import { AxiosResponse } from 'axios';
import bind from 'bind-decorator';
import * as React from 'react';
import { RefObject } from 'react';
import * as ReactDOM from 'react-dom';
import { debounce, ErrorStates, PanelPosition } from './AuthenticationApp';


interface CriteriaProps {
    message: string;
    passed: boolean;
}

const checkMark = (
    <svg className="material-icons md-16">
        <use xlinkHref="/res/sys/icons/material-icons.svg#check_circle"/>
    </svg>
);

const invalid = (
    <svg className="material-icons md-16">
        <use xlinkHref="/res/sys/icons/material-icons.svg#cancel"/>
    </svg>
);

class Criteria extends React.Component<CriteriaProps, any> {

    @bind
    getIconClass() {
        return `criteria-icon ${this.props.passed ? "valid" : "invalid"}`;
    }

    render() {
        return (
            <span className={this.getIconClass()}>
                { this.props.passed ? checkMark : invalid }
                {this.props.message}
            </span>
        );
    }

}

interface FieldInputProps {
    onKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>);
    onChange?(e: React.ChangeEvent<HTMLInputElement>);
    onFieldStateChange(state: string);
    forwardRef: RefObject<HTMLInputElement>;
    disabled: boolean;
}

class FieldInput extends React.Component<FieldInputProps , any> {

    @bind
    onFocus(): void {
        this.props.onFieldStateChange("active");
    }

    @bind
    onBlur(e: React.FocusEvent<HTMLInputElement>) {
        const target: HTMLInputElement = e.target as HTMLInputElement;
        this.props.onFieldStateChange(target.value == "" ? "inactive" : "text-entered");
    }

    render() {
        return (
            <input
                onKeyDown={this.props.onKeyDown}
                onChange={this.props.onChange}
                type="password"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                autoComplete="off"
                ref={this.props.forwardRef}
                disabled={this.props.disabled}
            />
        );
    }
}

interface PasswordResetContentProps {
    onKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>): void;
    onValidityChange(isValid: boolean, isError: boolean): void;
    validateHistory(newPassword: string): Promise<AxiosResponse>;
    position: PanelPosition;
    username: string;
    error: ErrorStates;
    policy?: PasswordResetPolicy;
    passwordRef: RefObject<HTMLInputElement>;
    passwordConfirmRef: RefObject<HTMLInputElement>;
}

interface PasswordResetContentPageState {
    fieldState: string;
    confirmFieldState: string;
    showPopover: boolean;
    lengthPassed: boolean;
    complexityPassed: boolean;
    historyPassed: boolean;
    fieldInitiated: boolean;
    confirmFieldInitiated: boolean;
}

export interface PasswordResetPolicy {
    pwdHistory: number;
    pwdMinLength: number;
    pwdComplexity: number;
}

export default class PasswordResetContent
    extends React.Component<PasswordResetContentProps, PasswordResetContentPageState> {

    handleValidation;
    panelWrapper;
    isError;

    constructor(props) {
        super(props);
        this.handleValidation = debounce(this.validateAll, 500);
        this.state = {
            fieldState: "inactive",
            confirmFieldState: "inactive",
            showPopover: true,
            lengthPassed: false,
            complexityPassed: false,
            historyPassed: false,
            fieldInitiated: false,
            confirmFieldInitiated: false
        };
    }

    componentDidMount() {
        this.panelWrapper = document.getElementById("login-panel-content");
    }

    public componentDidUpdate(prevProps: Readonly<PasswordResetContentProps>) {
        const { policy, position, passwordRef } = this.props;
        if (prevProps.policy !== policy) {
            this.setState({
                lengthPassed: policy?.pwdMinLength === 0,
                complexityPassed: policy?.pwdComplexity === 0,
                historyPassed: policy?.pwdHistory === 0
            });
            if ((passwordRef.current?.value ?? "") !== "") {
                this.validateAll();
            }
        }

        if (prevProps.position !== position && position === PanelPosition.DISPLAYED) {
            setTimeout(() => {
                passwordRef.current?.focus();
            }, 200);
        }
    }

    @bind
    onNewPasswordChange() {
        this.props.onValidityChange(false, this.isError);
        this.handleValidation();
    }

    @bind
    onConfirmPasswordChange() {
        const {
            lengthPassed,
            historyPassed,
            complexityPassed
        } = this.state;
        const validated = lengthPassed && historyPassed && complexityPassed;
        const password = this.props.passwordRef.current?.value ?? "";
        const confirmPassword = this.props.passwordConfirmRef.current?.value ?? "";
        this.props.onValidityChange(password === confirmPassword && validated, this.isError);
    }

    @bind
    validateAll() {
        const { lengthPassed, historyPassed, complexityPassed } = this.state;
        const password = this.props.passwordRef.current?.value ?? "";
        const isPasswordSame = password === (this.props.passwordConfirmRef.current?.value ?? "");
        const oldValidity = lengthPassed && historyPassed && complexityPassed;
        const newLengthPassed =  this.validatePasswordLength(password);
        const newComplexityPassed = this.validatePasswordComplexity(password);
        if (password === "") {
            this.setState({
                 lengthPassed: newLengthPassed,
                 complexityPassed: newComplexityPassed,
                 historyPassed: false
            });
            this.props.onValidityChange(false, this.isError);
            return;
        }
        this.props.validateHistory(password).then((res) => {
            let passed = false;
            if (res.status === 200) {
                passed = res.data === true;
            } else {
                console.warn(`Failed to validate password. ${res.status}: ${res.statusText}`);
            }
            this.setState({
                lengthPassed: newLengthPassed,
                complexityPassed: newComplexityPassed,
                historyPassed: passed
            });
            if (oldValidity !== (newLengthPassed && newComplexityPassed && passed && isPasswordSame)) {
                this.isError = false;
                this.props.onValidityChange(
                    newLengthPassed && newComplexityPassed && passed && isPasswordSame,
                    this.isError
                );
            }
        }).catch(err => {
            console.warn("Failed to validate password history.", err);
            this.setState({
                lengthPassed: newLengthPassed,
                complexityPassed: newComplexityPassed,
                historyPassed: false
            });
            this.isError = true;
            this.props.onValidityChange(false, this.isError);
        });
    }

    @bind
    validatePasswordLength(password: string) {
        return password?.length >= (this.props.policy?.pwdMinLength ?? 1);
    }

    @bind
    validatePasswordComplexity(password: string) {
        const complexity = this.props.policy?.pwdComplexity ?? 1;
        if (complexity > 1) {
            let lower = 0;
            let upper = 0;
            let digit = 0;
            let special = 0;

            for (const element of password) {
                if (element === element.toLowerCase() && element !== element.toUpperCase()) {
                    lower = 1;
                } else if (element === element.toUpperCase() && element !== element.toLowerCase()) {
                    upper = 1;
                } else if (Number.isInteger(Number.parseInt(element))) {
                    digit = 1;
                } else {
                    special = 1;
                }
            }
            return (lower + upper + digit + special) >= complexity;
        } else {
            return password.length > 1;
        }
    }

    @bind
    onFieldStateChange(state: string) {
        this.setState({
            fieldState: state,
            fieldInitiated: state === "active" || this.state.fieldInitiated
        });
    }

    @bind
    onConfirmFieldStateChange(state: string) {
        this.setState({
            confirmFieldState: state,
            confirmFieldInitiated: state === "active" || this.state.confirmFieldInitiated
        });
    }

    @bind
    renderCriteriaPopover() {

        const { position, policy } = this.props;

        if (policy === undefined) {
            return null;
        }

        const {
            fieldInitiated,
            fieldState,
            lengthPassed,
            historyPassed,
            complexityPassed
        } = this.state;

        if (position !== PanelPosition.DISPLAYED
            || this.panelWrapper === null
            || !fieldInitiated
            || fieldState !== "active") {
            return null;
        }

        const criteria: Array<JSX.Element> = [];

        if (policy.pwdMinLength > 0) {
            criteria.push(
                <Criteria
                    key={"min-length-criteria"}
                    message={`${policy.pwdMinLength} character(s) minimum.`}
                    passed={lengthPassed}
                />
            );
        }

        if (policy.pwdHistory > 0) {
            criteria.push(
                <Criteria
                    key={"history-criteria"}
                    message={"Cannot re-use the previous password."}
                    passed={historyPassed}
                />
            );
        }

        if (policy.pwdComplexity > 1) {
            criteria.push(
                <Criteria
                    key={"min-complexity-criteria"}
                    message={`Include at least ${policy.pwdComplexity} of the following: Lowercase, uppercase, digits, punctuation`}
                    passed={complexityPassed}
                />
            );
        }

        const popover = (
            <div className="criteria-popover-container">
                <div className="arrow"/>
                <div className="criteria-popover">{...criteria}</div>
            </div>
        );

        return ReactDOM.createPortal(popover, this.panelWrapper);
    }

    @bind
    maybeRenderPasswordError() {
        const {
            lengthPassed,
            complexityPassed,
            historyPassed,
            fieldState,
            fieldInitiated
        } = this.state;

        const failed = !lengthPassed || !complexityPassed || !historyPassed;
        return this.props.policy !== undefined && (failed && fieldState !== "active" && fieldInitiated) ?
        (
            <div className="error-display">
                The password does not meet the policy requirement.
            </div>
        ) : null;
    }

    @bind
    maybeRenderConfirmPasswordError() {
        const { passwordRef, passwordConfirmRef } = this.props;
        const { confirmFieldState, confirmFieldInitiated, fieldState } = this.state;
        const shouldRender = passwordRef.current?.value !== passwordConfirmRef.current?.value
            && confirmFieldState !== "active" && confirmFieldInitiated && fieldState !== "active";
        return shouldRender ?
        (
            <div className="error-display">
                Passwords do not match.
            </div>
        ) : null;
    }

    render() {
        const errorClass: string = this.props.error ? "error" : "";
        const { position, onKeyDown, passwordRef, passwordConfirmRef } = this.props;
        return (
            <React.Fragment>
                { this.renderCriteriaPopover() }
                <div className={`slide-display ${position}`}>
                    <div className="input-wrapper" style={{ marginBottom: "2rem" }}>
                        <label className={ `input-label label-${this.state.fieldState} ${errorClass}` }>
                            New Password
                        </label>
                        <FieldInput
                            onKeyDown={onKeyDown}
                            onFieldStateChange={this.onFieldStateChange}
                            onChange={this.onNewPasswordChange}
                            forwardRef={passwordRef}
                            disabled={position !== PanelPosition.DISPLAYED}
                        />
                        { this.maybeRenderPasswordError() }
                    </div>
                    <div className="input-wrapper">
                        <label className={ `input-label label-${this.state.confirmFieldState} ${errorClass}` }>
                            Confirm New Password
                        </label>
                        <FieldInput
                            onKeyDown={onKeyDown}
                            onFieldStateChange={this.onConfirmFieldStateChange}
                            onChange={this.onConfirmPasswordChange}
                            forwardRef={passwordConfirmRef}
                            disabled={position !== PanelPosition.DISPLAYED}
                        />
                        { this.maybeRenderConfirmPasswordError() }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}