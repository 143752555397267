import Axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import bind from 'bind-decorator';
import Tooltip from 'rc-tooltip';
import * as React from "react";
import {ReactNode} from "react";
import * as ReactDOM from "react-dom";
import PasswordResetContent, { PasswordResetPolicy } from './PasswordResetContent';

// Quick and dirty.  Make sure it's on the page for server side rendering.
window.ReactDOM = ReactDOM;
window.React = React;

require('../scss/main.scss');

export enum ErrorStates {
    NONE = "",
    EMPTY = "Input is required",
    INVALID = "Login failed. Please try again.",
    INVALID_MULTIPLE = "Login failed. Please contact your Gateway admin to resolve this issue.",
    PASSWORD_EXPIRED = "Login failed. Password has expired."
}

export enum AuthStep {
    NEW = "new",
    BADGE = "badge",
    USERNAME = "username",
    PASSWORD = "password",
    VERIFY = "verify",
    PASSWORD_RESET = "password_reset"
}

enum AuthenticationMethod {
    USERNAME_AND_PASSWORD = "username-and-password",
    BADGE = "badge"
}

export enum PanelPosition {
    LEFT = "off-panel-left",
    DISPLAYED = "on-panel",
    RIGHT= "off-panel-right"
}

interface LoginProps {
    platformEdition: PlatformEdition;
    coBrandingEnabled: boolean;
}

interface LoginCompState {
    passwordAttempts: number;
    authMethods?: Array<AuthenticationChallengeConfig>;
    steps: Array<AuthStep>;
    username: string;
    error: ErrorStates;
    fieldState: string;
    isLoading: boolean;
    badgeSecret: boolean;
    badgeError: boolean;
    smallLayout: boolean;
    policy?: PasswordResetPolicy;
    rememberMe: boolean;
    passwordUpdateValid: boolean;
    passwordUpdateError: boolean;
}

interface TokenBearingMessage {
    token: string;
}

type NextChallengeRequest = TokenBearingMessage

interface NextChallengeResponse extends TokenBearingMessage {
    complete: boolean;
    nextChallenge: Array<AuthenticationChallengeConfig>;
    rememberMe: boolean;
    passwordExpired: boolean;
}

interface ChallengeRequest extends TokenBearingMessage {
    rememberMe?: boolean;
}

interface UsernamePasswordChallengeRequest extends ChallengeRequest {
    username: string;
    password: string;
}

interface BadgeChallengeRequest extends ChallengeRequest {
    badge: string;
    secret?: string;
}

interface ChallengeResponse extends TokenBearingMessage {
    success: boolean;
    reason: string;
}

interface PasswordUpdateRequest extends TokenBearingMessage {
    oldPassword: string;
    newPassword: string;
}

interface AuthenticationChallengeConfig {
    type: string;
    config: any;
}

interface BadgeAuthenticationChallengeConfig {
    badgeSecret: boolean;
}

interface OIDCAuthCodeRequest {
    response_type?: string;
    client_id?: string;
    redirect_uri?: string;
    state?: string;
    scope?: string;
    nonce?: string;
    prompt?: string;
    max_age?: string;
    app?: string;
}

const HTTP_TIMEOUT: number = 120000; // milliseconds

enum PlatformEdition {
    MAKER = 'maker',
    STANDARD = 'standard',
    EDGE = 'edge'
}

enum App {
    GATEWAY = 'gateway',
    DESIGNER = 'designer',
    VISION = 'vision',
    PERSPECTIVE = 'perspective'
}

export const debounce = (fn: () => any, delay: number = 0) => {
    let timeoutId: any;
    return function (...args: any) {
        clearTimeout(timeoutId);
        // @ts-ignore
        timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
};

const SMALL_BREAKPOINT = 768;

/**
 * TODO - break this out into modular authenticator system
 */
export class AuthenticationApp extends React.Component<LoginProps, LoginCompState> {

    private static readonly QUERY_PARAMS: Map<string, string> = AuthenticationApp.gatherQueryParams();

    state: LoginCompState;

    private badge: string = ``;
    private badgeTimeout: number | undefined = undefined;
    private usernameField: HTMLInputElement | null;
    private passwordField: HTMLInputElement | null;
    private rememberMeField: HTMLInputElement | null;
    private verifyField: HTMLInputElement | null;
    private newPasswordField: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    private confirmNewPasswordField: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    private readonly idpName: string;

    private readonly oidcAuthCodeRequest: OIDCAuthCodeRequest;

    private token: string | undefined;
    handleWindowResize = debounce(this.onWindowResize, 50);

    challengeTimeout: number | null;

    constructor(props: LoginProps) {
        super(props);
        this.idpName = AuthenticationApp.getIdpName();
        this.oidcAuthCodeRequest = AuthenticationApp.getOidcAuthCodeRequest();
        this.token = AuthenticationApp.QUERY_PARAMS.get(`token`);
        this.state = {
            authMethods: undefined,
            steps: [AuthStep.NEW],
            username: "",
            passwordAttempts: 0,
            error: ErrorStates.NONE,
            fieldState: "inactive",
            isLoading: false,
            badgeSecret: false,
            badgeError: false,
            smallLayout: window.innerWidth < SMALL_BREAKPOINT,
            rememberMe: false,
            passwordUpdateValid: false,
            passwordUpdateError: false,
            policy: undefined
        };
        this.challengeTimeout = window.setTimeout(this.getNextChallenge, 0);
    }

    private static getOidcAuthCodeRequest(): OIDCAuthCodeRequest {
        const response_type: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`response_type`);
        const client_id: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`client_id`);
        const redirect_uri: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`redirect_uri`);
        const state: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`state`);
        const scope: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`scope`);
        const nonce: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`nonce`);
        const prompt: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`prompt`);
        const max_age: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`max_age`);
        const app: string | undefined = AuthenticationApp.QUERY_PARAMS.get(`app`);
        return {
            response_type,
            client_id,
            redirect_uri,
            state,
            scope,
            nonce,
            prompt,
            max_age,
            app
        };
    }

    @bind
    private getOidcAuthCodeRequestUri(cancel?: boolean): string {
        const search: string = Object.keys(this.oidcAuthCodeRequest).map(key => {
            if (this.oidcAuthCodeRequest[key]) {
                return `${encodeURIComponent(key)}=${encodeURIComponent(this.oidcAuthCodeRequest[key])}`;
            } else {
                return undefined;
            }
        }).filter(param => !!param).join(`&`);
        if (cancel !== undefined && cancel) {
            return `/idp/${ this.idpName }/oidc/auth?${ search }${ this.token ? `&token=${ this.token }` : `` }&cancel=${ cancel }`;
        } else {
            return `/idp/${ this.idpName }/oidc/auth?${ search }${ this.token ? `&token=${ this.token }` : `` }`;
        }
    }

    @bind
    private onBadgeTimeout(clearBadge: boolean = true): void {
        if (clearBadge) {
            this.badge = ``;
        }
        if (this.badgeTimeout !== undefined) {
            window.clearTimeout(this.badgeTimeout);
            this.badgeTimeout = undefined;
        }
    }

    @bind
    private getCurrentStep(): AuthStep | undefined {
        const { steps } = this.state;
        const { length } = steps;
        if (length > 0) {
            return steps[length - 1];
        } else {
            return undefined;
        }
    }

    @bind
    private getPreviousStep(): AuthStep | undefined {
        const { steps } = this.state;
        const { length } = steps;
        if (length > 1) {
            return steps[length - 2];
        } else {
            return undefined;
        }
    }

    @bind
    private onDocumentKeyPress(event: KeyboardEvent): void {
        const currentStep: AuthStep | undefined = this.getCurrentStep();
        if (event.keyCode === 13 && currentStep === AuthStep.BADGE) {
            this.submitBadge();
        } else if (event.keyCode === 27 && currentStep != AuthStep.USERNAME) { // ESC goes back
            this.backUpStep();
        } else if (currentStep === AuthStep.BADGE) {
            this.badge += event.key;
            if (this.badgeTimeout === undefined) {
                this.badgeTimeout = window.setTimeout(this.onBadgeTimeout, 1000);
            }
        }
    }
    
    @bind
    onWindowResize() {
        const { smallLayout } = this.state;
        const innerWidth: number = window.innerWidth;
        if (innerWidth < SMALL_BREAKPOINT && !smallLayout) {
            this.setState({ smallLayout: true });
        } else if (innerWidth >= SMALL_BREAKPOINT && smallLayout) {
            this.setState({ smallLayout: false });
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        document.addEventListener(`keypress`, this.onDocumentKeyPress);
        const currentStep: AuthStep | undefined = this.getCurrentStep();
        this.disableOffPanelFields(currentStep);
        const field: HTMLInputElement | null = currentStep === AuthStep.USERNAME ? this.usernameField : null;

        if (field) {
            field.focus();
            setTimeout(() => {
                // if field can't autofocus (e.g. devtools or something retains focus)
                // check if browser or plugin has auto-added input after load
                if (field && field.value != "" && this.state.fieldState === "inactive") {
                    this.setState({ fieldState: "text-entered" });
                }
            }, 150);
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(`keypress`, this.onDocumentKeyPress);
        window.removeEventListener('resize', this.onWindowResize);
        this.challengeTimeout && window.clearTimeout(this.challengeTimeout);
        this.challengeTimeout = null;
    }

    componentDidUpdate(prevProps: LoginProps, prevState: LoginCompState) {
        const currentStep: AuthStep | undefined = this.getCurrentStep();
        if (currentStep != prevState.steps[prevState.steps.length - 1]) {
            this.enableSubmitButton();
            const field = this.getCurrentStepField(currentStep);
            if (field) {
                this.disableOffPanelFields(currentStep);
                setTimeout(() => {
                    field.focus();
                }, 200);
            }

            if (currentStep === AuthStep.PASSWORD_RESET) {
                Axios.post(`password-policy`, { token: this.token ?? "" }).then((res: AxiosResponse<PasswordResetPolicy>) => {
                    if (res.status === 200) {
                        const policy: PasswordResetPolicy = res.data;
                        this.setState({ policy });
                    }
                }).catch(err => {
                    console.warn("Failed to retrieve password policy.", err);
                });
            }

        }
    }

    /**
     * based on http://openid.net/specs/openid-connect-core-1_0.html#FragmentNotes
     */
    private static gatherQueryParams(): Map<string, string> {
        const params: Map<string, string> = new Map();
        const postBody: string = location.search.substring(1);
        const regex: RegExp = /([^&=]+)=([^&]*)/g;
        let m: RegExpExecArray | null;
        // eslint-disable-next-line no-cond-assign
        while (m = regex.exec(postBody)) {
            params.set(decodeURIComponent(m[1]), decodeURIComponent(m[2]));
        }
        return params;
    }

    private static getIdpName(): string {
        const regex: RegExp = /^\/idp\/([A-Za-z0-9_]+)\/.*/;
        const path: string = location.pathname;
        const m: RegExpExecArray | null = regex.exec(path);
        if (!m) {
            throw Error(`Unable to get the IdP name from the path`);
        }
        return m[1];
    }

    @bind
    disableOffPanelFields(step?: AuthStep) {
        if (this.usernameField && this.passwordField && this.verifyField) {
            this.usernameField.style.display = step === AuthStep.USERNAME ? "inline-block" : "none";
            this.passwordField.style.display = step === AuthStep.PASSWORD ? "inline-block" : "none";
            this.verifyField.style.display = step === AuthStep.VERIFY ? "inline-block" : "none";
        }
    }

    @bind
    getCurrentStepField(step?: AuthStep): HTMLInputElement | null {
        if (step === AuthStep.BADGE) {
            return null;
        } else if (step === AuthStep.PASSWORD) {
            return this.passwordField;
        } else if (step === AuthStep.VERIFY) {
            return this.verifyField;
        } else {
            return this.usernameField;
        }
    }

    @bind
    submitUsername(): void {
        const username: string = this.usernameField ? this.usernameField.value : "";
        if (username && username != "") {
            this.setState(
                {
                    username: username,
                    passwordAttempts: 0,
                    steps: [...this.state.steps, AuthStep.PASSWORD],
                    error: ErrorStates.NONE
                }
            );
        } else {
            this.setState({ error: ErrorStates.EMPTY });
        }
    }

    @bind
    onUsernamePasswordChallengeFulfilled(response: AxiosResponse): void {
        if (response.status !== 200) {
            console.error(`Unable to get the username password challenge response: ${response.statusText}`);
            this.redirectToAuthorizationEndpoint();
            return;
        }
        const usernamePasswordChallengeResponse: ChallengeResponse = response.data as ChallengeResponse;
        this.token = usernamePasswordChallengeResponse.token;
        if (usernamePasswordChallengeResponse.success) {
            console.debug(`Username Password Challenge Passed`);
            this.getNextChallenge();
        } else {
            console.debug(`Username Password Challenge Failed`);
            const newPasswordAttempts = this.state.passwordAttempts + 1;
            this.setState(
                {
                    passwordAttempts: newPasswordAttempts,
                    error: newPasswordAttempts > 5 ? ErrorStates.INVALID_MULTIPLE : ErrorStates.INVALID
                }
            );
            this.enableSubmitButton();
        }
    }

    @bind
    onUsernamePasswordChallengeError(reason: any): void {
        console.error(`Unable to get the username password challenge response: ${reason}`);
    }

    @bind
    authenticate(): void {
        const { username } = this.state;
        const password: string = this.passwordField ? this.passwordField.value : "";
        const token: string = this.token || '';
        const rememberMe: boolean | undefined = this.isRememberMeChecked();
        this.disableSubmitButton();
        const url: string = `submit-username-password-challenge`;
        const request: UsernamePasswordChallengeRequest = { username, password, token, rememberMe };
        const config: AxiosRequestConfig = {
            timeout: HTTP_TIMEOUT
        };
        Axios.post(url, request, config)
            .then(this.onUsernamePasswordChallengeFulfilled)
            .catch(this.onUsernamePasswordChallengeError);
    }

    @bind
    onBadgeChallengeFulfilled(response: AxiosResponse): void {
        if (response.status !== 200) {
            console.error(`Unable to get the badge challenge response: ${response.statusText}`);
            this.redirectToAuthorizationEndpoint();
            return;
        }
        const badgeChallengeResponse: ChallengeResponse = response.data as ChallengeResponse;
        this.token = badgeChallengeResponse.token;
        if (badgeChallengeResponse.success) {
            console.debug(`Badge Challenge Passed`);
            this.getNextChallenge();
        } else {
            console.debug(`Badge Challenge Failed`);
            const currentStep: AuthStep | undefined = this.getCurrentStep();
            if (currentStep === AuthStep.BADGE) {
                this.setState({ badgeError: true });
            } else if (currentStep === AuthStep.PASSWORD) {
                const newPasswordAttempts = this.state.passwordAttempts + 1;
                this.setState(
                    {
                        passwordAttempts: newPasswordAttempts,
                        error: newPasswordAttempts > 5 ? ErrorStates.INVALID_MULTIPLE : ErrorStates.INVALID
                    }
                );
            }
            this.enableSubmitButton();
        }
    }

    @bind
    onBadgeChallengeError(reason: any): void {
        console.error(`Unable to get the badge challenge response: ${ reason }`);
        this.setState({ badgeError: true });
        const currentStep: AuthStep | undefined = this.getCurrentStep();
        if (currentStep === AuthStep.BADGE) {
            this.onBadgeTimeout();
        }
    }

    @bind
    submitBadgeChallenge(badge: string, token: string, secret?: string, rememberMe?: boolean): void {
        const url: string = `submit-badge-challenge`;
        const request: BadgeChallengeRequest = { badge, token, secret, rememberMe };
        const config: AxiosRequestConfig = {
            timeout: HTTP_TIMEOUT
        };
        Axios.post(url, request, config)
            .then(this.onBadgeChallengeFulfilled)
            .catch(this.onBadgeChallengeError);
    }

    @bind
    submitBadge(): void {
        const badge: string = this.badge;
        const token: string = this.token || '';
        if (badge && badge != "") {
            this.disableSubmitButton();
            if (this.state.badgeSecret) {
                this.onBadgeTimeout(false);
                this.setState({ steps: [...this.state.steps, AuthStep.PASSWORD] });
            } else {
                this.onBadgeTimeout();
                this.submitBadgeChallenge(badge, token);
            }
        }
    }

    @bind
    private isRememberMeChecked(): boolean | undefined {
        if (this.state.rememberMe) {
            if (this.rememberMeField) {
                return this.rememberMeField.checked;
            } else {
                return false;
            }
        }
        return undefined;
    }

    @bind
    submitBadgeAndSecret(): void {
        const badge: string = this.badge;
        const secret: string | undefined = this.passwordField ? this.passwordField.value : "";
        const token: string = this.token || '';
        if (secret && secret != "") {
            const rememberMe: boolean | undefined = this.isRememberMeChecked();
            this.disableSubmitButton();
            this.submitBadgeChallenge(badge, token, secret, rememberMe);
        } else {
            this.setState({ error: ErrorStates.EMPTY });
        }
    }

    @bind
    verify(): void {
        const verifyValue = this.verifyField ? this.verifyField.value : "";
        // TODO submit for 2FA
        if (verifyValue && verifyValue != "") {
            console.log("VERIFY", verifyValue);
        } else {
            this.setState({ error: ErrorStates.EMPTY });
        }
    }

    @bind
    onNextChallengeFulfilled(response: AxiosResponse): void {
        if (response.status !== 200) {
            console.error(`Unable to get the next challenge response: ${response.statusText}`);
            this.redirectToAuthorizationEndpoint();
            return;
        }
        const nextChallengeResponse: NextChallengeResponse = response.data as NextChallengeResponse;
        this.token = nextChallengeResponse.token;
        if (nextChallengeResponse.complete) {
            console.log(`All auth challenges completed`);
            this.redirectToAuthorizationEndpoint();
        } else {
            const authMethods: Array<AuthenticationChallengeConfig> = nextChallengeResponse.nextChallenge;
            if (authMethods?.length > 0) {
                const preferredNextChallenge: AuthenticationChallengeConfig = authMethods[0];
                let badgeSecret: boolean = false;
                for (const challenge of authMethods) {
                    if (challenge.type === AuthenticationMethod.BADGE) {
                        const config = challenge.config as BadgeAuthenticationChallengeConfig;
                        badgeSecret = config.badgeSecret;
                    }
                }
                const nextStep: AuthStep =
                    preferredNextChallenge.type === AuthenticationMethod.BADGE ? AuthStep.BADGE : AuthStep.USERNAME;
                const rememberMe: boolean = nextChallengeResponse.rememberMe;
                this.setState({ authMethods, steps: [...this.state.steps, nextStep], badgeSecret, rememberMe });
            } else {
                if (nextChallengeResponse.passwordExpired) {
                    const rememberMe: boolean = nextChallengeResponse.rememberMe;
                    this.setState({ authMethods: undefined, steps: [ AuthStep.PASSWORD_RESET ], rememberMe });
                } else {
                    console.error(`Next challenge response is incomplete, but got no auth methods`);
                    this.redirectToAuthorizationEndpoint();
                }
            }
        }
    }

    @bind
    onNextChallengeError(reason: any): void {
        if (reason.response) {
            console.error(`Unable to get the next challenge response: ${reason.response.statusText}`);
            this.redirectToAuthorizationEndpoint();
            return;
        } else {
            console.error(`Unable to get the next challenge response: ${reason}`);
            console.log(`Next attempt to get next challenge will be fired in 10 seconds`);
            window.setTimeout(this.getNextChallenge, 10000);
        }
    }

    private redirectToAuthorizationEndpoint(cancel?: boolean): void {
        const oidcAuthCodeRequestUri = this.getOidcAuthCodeRequestUri(cancel);
        console.log(`Redirecting to ${oidcAuthCodeRequestUri}`);
        window.location.assign(oidcAuthCodeRequestUri);
    }

    @bind
    getNextChallenge(): void {
        const token: string = this.token || ``;
        const url: string = `next-challenge`;
        const data: NextChallengeRequest = { token };
        const config: AxiosRequestConfig = {
            timeout: HTTP_TIMEOUT
        };
        Axios.post(url, data, config)
            .then(this.onNextChallengeFulfilled)
            .catch(this.onNextChallengeError);
    }

    @bind
    validatePasswordHistory(newPassword: string): Promise<AxiosResponse> {
        const data = { password: newPassword, token: this.token || "" };
        return Axios.post("validate-password-history", data);
    }

    @bind
    submitPasswordReset() {
        const data: PasswordUpdateRequest = {
            oldPassword: this.passwordField?.value ?? "",
            newPassword: this.newPasswordField.current?.value || "",
            token: this.token ?? ""
        };
        this.disableSubmitButton();
        const config: AxiosRequestConfig = {
            timeout: HTTP_TIMEOUT
        };
        this.setState({ passwordUpdateError: false });
        Axios.post("update-password", data, config)
            .then(this.onSubmitPasswordResetFulfilled)
            .catch(this.onSubmitPasswordResetError);
    }

    @bind
    onSubmitPasswordResetFulfilled(response: AxiosResponse): void {
        if (response.status !== 200) {
            console.error(`Unable to get the username password challenge response: ${response.statusText}`);
            this.redirectToAuthorizationEndpoint();
            return;
        }
        const passwordResetResponse: ChallengeResponse = response.data as ChallengeResponse;
        this.token = passwordResetResponse.token;
        if (passwordResetResponse.success) {
            console.debug(`Password Reset Challenge Passed`);
            this.getNextChallenge();
        } else {
            console.debug(`Password Reset Challenge Failed`);
            this.enableSubmitButton();
            this.setState({ passwordUpdateError: true });
        }
    }

    @bind
    onSubmitPasswordResetError(reason: any): void {
        console.error(`Unable to get the password reset challenge response: ${reason}`);
        this.enableSubmitButton();
        this.setState({ passwordUpdateError: true });
    }

    @bind
    disableSubmitButton() {
        this.setState({ isLoading: true });
    }

    @bind
    enableSubmitButton() {
        this.setState({ isLoading: false });
    }

    @bind
    onPasswordUpdateValidityChange(isValid: boolean, isError?: boolean) {
        this.setState({ passwordUpdateValid: isValid, passwordUpdateError: !!isError });
    }

    @bind
    backUpStep() {
        // TODO get auth state to decide this
        const steps: Array<AuthStep> = this.state.steps.slice(0, -1);
        const lastStep: AuthStep = steps[steps.length - 1];
        if (lastStep === AuthStep.BADGE) {
            this.onBadgeTimeout();
        }
        this.setState(
            {
                passwordAttempts: 0,
                steps,
                error: ErrorStates.NONE,
                badgeError: false
            }
        );
    }

    @bind
    onKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement> ): void {
        const currentStep: AuthStep | undefined = this.getCurrentStep();
        const previousStep: AuthStep | undefined = this.getPreviousStep();
        if (e.keyCode === 13) {             // ENTER submits
            if (currentStep === AuthStep.USERNAME) {
                this.submitUsername();
            } else if (currentStep === AuthStep.PASSWORD) {
                if (previousStep === AuthStep.USERNAME) {
                    this.authenticate();
                } else if (previousStep === AuthStep.BADGE) {
                    this.submitBadgeAndSecret();
                }
            } else if (currentStep === AuthStep.VERIFY) {
                this.verify();
            } else if (currentStep === AuthStep.PASSWORD_RESET && this.state.passwordUpdateValid) {
                this.submitPasswordReset();
            }
        }
    }

    @bind
    onFocus(e: React.FocusEvent<HTMLInputElement>): void {
        // cast because callback is only attached to input elements
        const target: HTMLInputElement = e.target as HTMLInputElement;
        const currentStep: AuthStep | undefined = this.getCurrentStep();
        if (this && target.name === currentStep) {
            this.setState({ fieldState: "active" });
        }
    }

    @bind
    onBlur(e: React.FocusEvent<HTMLInputElement>) {
        const target: HTMLInputElement = e.target as HTMLInputElement;
        const currentStep: AuthStep | undefined = this.getCurrentStep();
        if (this && target.name === currentStep) {
            if (target.value == "") {
                this.setState({fieldState: "inactive"});
            } else {
                this.setState({fieldState: "text-entered"});
            }
        }
    }

    @bind
    retryLogin() {
        this.redirectToAuthorizationEndpoint();
    }

    @bind
    getPanelHeader(step: AuthStep) {
        let badgeHeaderPosition, projectHeaderPosition, verifyHeaderPosition, resetHeaderPosition;
        if (step === AuthStep.VERIFY) {
            badgeHeaderPosition = PanelPosition.LEFT;
            projectHeaderPosition = PanelPosition.LEFT;
            resetHeaderPosition = PanelPosition.LEFT;
            verifyHeaderPosition = PanelPosition.DISPLAYED;
        } else if (step === AuthStep.BADGE) {
            badgeHeaderPosition = PanelPosition.DISPLAYED;
            projectHeaderPosition = PanelPosition.RIGHT;
            resetHeaderPosition = PanelPosition.RIGHT;
            verifyHeaderPosition = PanelPosition.RIGHT;
        } else if (step === AuthStep.PASSWORD_RESET) {
            badgeHeaderPosition = PanelPosition.LEFT;
            projectHeaderPosition = PanelPosition.LEFT;
            verifyHeaderPosition = PanelPosition.LEFT;
            resetHeaderPosition = PanelPosition.DISPLAYED;
        } else {
            badgeHeaderPosition = PanelPosition.LEFT;
            projectHeaderPosition = PanelPosition.DISPLAYED;
            verifyHeaderPosition = PanelPosition.RIGHT;
            resetHeaderPosition = PanelPosition.RIGHT;
        }

        return (
            <div className={ `panel-section panel-header` }>
                <div className={ `slide-display ${ badgeHeaderPosition }` }>
                    <p className="panel-description">Scan your Badge.</p>
                </div>
                <div className={ `slide-display ${ projectHeaderPosition }` }>
                    <p className="panel-description">Log In to continue</p>
                </div>
                <div className={ `slide-display ${ verifyHeaderPosition }` }>
                    <h2 className="panel-title">Enter 2-Step Verification Code</h2>
                    <p className="panel-description">You should receive a text with this code</p>
                </div>
                <div className={ `slide-display ${ resetHeaderPosition }` }>
                    <p className="panel-description">
                        Your account requires periodic password updates for security purposes.
                    </p>
                </div>
            </div>
        );
    }

    @bind
    renderLogInAs(): ReactNode {
        const { username } = this.state;
        const previousStep: AuthStep | undefined = this.getPreviousStep();
        if (previousStep === AuthStep.USERNAME) {
            return (
                <React.Fragment>
                    <label>Log in as:</label>
                    <div className="entered-username">{ username }</div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    @bind
    getPanelContent(step: AuthStep) {
        const { fieldState, error, rememberMe } = this.state;
        const errorClass: string = error ? "error" : "";
        const perspectiveClass = App.PERSPECTIVE === this.oidcAuthCodeRequest.app ? "perspective" : "";
        let badgeContent, usernameContent, passwordContent, passwordResetContent, verifyContent;
        if (step === AuthStep.PASSWORD) {
            badgeContent = PanelPosition.LEFT;
            usernameContent = PanelPosition.LEFT;
            passwordContent = PanelPosition.DISPLAYED;
            passwordResetContent = PanelPosition.RIGHT;
            verifyContent = PanelPosition.RIGHT;
        } else if (step === AuthStep.VERIFY) {
            badgeContent = PanelPosition.LEFT;
            usernameContent = PanelPosition.LEFT;
            passwordContent = PanelPosition.LEFT;
            passwordResetContent = PanelPosition.RIGHT;
            verifyContent = PanelPosition.DISPLAYED;
        } else if (step === AuthStep.BADGE) {
            badgeContent = PanelPosition.DISPLAYED;
            usernameContent = PanelPosition.RIGHT;
            passwordContent = PanelPosition.RIGHT;
            passwordResetContent = PanelPosition.RIGHT;
            verifyContent = PanelPosition.RIGHT;
        } else if (step === AuthStep.PASSWORD_RESET) {
            badgeContent = PanelPosition.LEFT;
            usernameContent = PanelPosition.LEFT;
            passwordContent = PanelPosition.LEFT;
            passwordResetContent = PanelPosition.DISPLAYED;
            verifyContent = PanelPosition.RIGHT;
        } else {
            badgeContent = PanelPosition.LEFT;
            usernameContent = PanelPosition.DISPLAYED;
            passwordContent = PanelPosition.RIGHT;
            verifyContent = PanelPosition.RIGHT;
            passwordResetContent = PanelPosition.RIGHT;
        }

        const scanBadgeImgClass: string = step === AuthStep.BADGE ? `badge-scan` : `badge-scan hidden`;

        const rememberMeToolTip: ReactNode = (
            <div className="login-tooltip">
                <span>
                    {`If selected, you'll be remembered on this device without needing to log in again.`}<br/>
                    {`Note: This is NOT recommended if you are using a public or shared device.`}
                </span>
            </div>
        );

        let errorBlock: JSX.Element | null = null;

        if (error.length > 0) {
            let errorInfo: JSX.Element | null = null;
            if (error === ErrorStates.INVALID || error === ErrorStates.INVALID_MULTIPLE) {
                const invalidToolTipContent = (
                    <div className="login-tooltip">
                        <span>
                            Possible causes for this error include: Invalid<br/>
                            credentials, account lockout, or server error.
                        </span>
                    </div>
                );
                errorInfo = (
                    <Tooltip
                        placement="bottomLeft"
                        destroyTooltipOnHide={true}
                        overlay={invalidToolTipContent}
                    >
                        { InfoIcon }
                    </Tooltip>
                );
            }


            errorBlock = (
                <div className="error-display">
                    { error }
                    { errorInfo }
                </div>
            );
        }

        return (
                <div className={ `panel-section panel-content` }>
                    <div className={ `slide-display ${badgeContent}` }>
                        <div className={ `img-wrapper` }>
                            <img
                                src="/res/sys/img/authentication/scan-badge.png"
                                alt="Scan your badge"
                                className={ scanBadgeImgClass }
                            />
                        </div>
                        {errorBlock}
                    </div>
                    <div className={ `slide-display ${usernameContent}` }>
                        <div className="input-wrapper">
                            <label className={ `input-label label-${fieldState} ${perspectiveClass}` }>Username</label>
                            <input
                                className={`username-field ${errorClass} ${perspectiveClass}`}
                                ref={ (field: HTMLInputElement | null) => this.usernameField = field}
                                onKeyDown={this.onKeyDown}
                                type="text"
                                name={AuthStep.USERNAME}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                autoComplete="off"
                                autoCapitalize="none"
                            />
                        </div>
                        {errorBlock}
                    </div>
                    <div className={ `slide-display ${passwordContent}` }>
                        { this.renderLogInAs() }
                        <div className="input-wrapper">
                            <label className={ `password-label input-label label-${fieldState} ${errorClass} ${perspectiveClass}` }>
                                Password
                            </label>
                            <input
                                className={`password-field ${errorClass} ${perspectiveClass}`}
                                ref={ (field: HTMLInputElement | null) => this.passwordField = field }
                                onKeyDown={this.onKeyDown}
                                type="password"
                                name={AuthStep.PASSWORD}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                autoComplete="off"
                            />
                        </div>
                        {errorBlock}
                        <div className={`input-wrapper remember-me${rememberMe ? `` : ` hidden`}`}>
                            <input
                                type="checkbox"
                                id="remember-me"
                                autoComplete="off"
                                className="remember-me"
                                ref={ (field: HTMLInputElement | null) => this.rememberMeField = field }
                            />
                            <label className="remember-me" htmlFor="remember-me">
                                Remember me on this device
                            </label>
                            <span className="small">&nbsp;(</span>
                                <Tooltip
                                    placement="bottomLeft"
                                    destroyTooltipOnHide={true}
                                    overlay={rememberMeToolTip}
                                >
                                    <a className="remember-me small">{`what's this?`}</a>
                                </Tooltip>
                                <span className="small">)</span>
                        </div>
                    </div>
                    <div className={ `slide-display ${verifyContent}` }>
                        <div className="input-wrapper">
                            <label className={ `input-label label-${fieldState} ${errorClass}` }>
                                Verification Code
                            </label>
                            <input
                                className={`${errorClass}`}
                                ref={ (field: HTMLInputElement | null) => this.verifyField = field }
                                onKeyDown={this.onKeyDown}
                                type="number"
                                name={AuthStep.VERIFY}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                autoComplete="off"
                            />
                        </div>
                        <div className="error-display">{ error }</div>
                    </div>
                    <PasswordResetContent
                        username={this.state.username}
                        onKeyDown={this.onKeyDown}
                        validateHistory={this.validatePasswordHistory}
                        onValidityChange={this.onPasswordUpdateValidityChange}
                        position={passwordResetContent}
                        error={error}
                        passwordRef={this.newPasswordField}
                        passwordConfirmRef={this.confirmNewPasswordField}
                        policy={this.state.policy}
                    />
                </div>
        );
    }

    @bind
    private transitionStep(nextStep: AuthStep): void {
        this.setState({
            passwordAttempts: 0,
            steps: [...this.state.steps.slice(0, -1), nextStep],
            error: ErrorStates.NONE, badgeError: false
        });
    }

    @bind
    private renderAltMethodLink(step: AuthStep): ReactNode {
        const { authMethods } = this.state;
        const userNameTransition = () => this.transitionStep(AuthStep.USERNAME);
        const badgeTransition = () => this.transitionStep(AuthStep.BADGE);
        if (authMethods) {
            if (step === AuthStep.BADGE && authMethods.find(authMethod => authMethod.type === AuthenticationMethod.USERNAME_AND_PASSWORD)) {
                return (
                    <div className={ `badge-panel-footer` }>
                        <a
                            onClick={ userNameTransition }
                        >
                            Login with Username and Password
                        </a>
                    </div>
                );
            } else if (step === AuthStep.USERNAME && authMethods.find(authMethod => authMethod.type === AuthenticationMethod.BADGE)) {
                return (
                    <div className={ `uname-panel-footer` }>
                        <a
                            onClick={ badgeTransition }
                        >
                            Login with Badge
                        </a>
                    </div>
                );
            }
        }
        return null;
    }

    @bind
    private renderButton(step: AuthStep,
                         buttonAction: React.EventHandler<React.SyntheticEvent<HTMLInputElement | HTMLDivElement>>,
                         buttonMessage: string,
                         isAboveLink: boolean): ReactNode {
        if (step === AuthStep.BADGE) {
            return null;
        } else {
            return (
                <SubmitButton
                    action={ buttonAction }
                    message={ buttonMessage }
                    isLoading={ this.state.isLoading }
                    passwordResetValid={ this.state.passwordUpdateValid }
                    keyDown={ this.onKeyDown }
                    isAboveLink={ isAboveLink }
                    className={App.PERSPECTIVE === this.oidcAuthCodeRequest.app ? "perspective" : undefined}
                />
            );
        }
    }

    @bind
    private getPanelFooter(step: AuthStep,
                           buttonAction: React.EventHandler<React.SyntheticEvent<HTMLInputElement | HTMLDivElement>>,
                           buttonMessage: string): ReactNode {
        const altMethodLink: ReactNode = this.renderAltMethodLink(step);
        return (
            <React.Fragment>
                { this.renderButton(step, buttonAction, buttonMessage, !!altMethodLink) }
                { altMethodLink }
            </React.Fragment>
        );
    }

    @bind
    renderLogo() {

        let iconCssClass: string = "";

        switch (this.oidcAuthCodeRequest.app) {
            case App.GATEWAY:
                iconCssClass = "gateway";
                break;
            case App.DESIGNER:
                iconCssClass = "designer";
                break;
            case App.PERSPECTIVE:
                iconCssClass = "perspective";
                break;
            case App.VISION:
                iconCssClass = "vision";
                break;
            default:
                break;
        }

        return <div className={`panel-icon ${iconCssClass}`}/>;
    }

    @bind
    getLoginStep(step?: AuthStep): JSX.Element {
        const { authMethods, error, rememberMe } = this.state;
        const previousStep: AuthStep | undefined = this.getPreviousStep();
        const perspectiveClass = App.PERSPECTIVE === this.oidcAuthCodeRequest.app ? "perspective" : "";
        let backArrow = !this.state.isLoading ?
            <svg className={`back-arrow material-icons md-24 ${perspectiveClass}`} onClick={ this.backUpStep }>
                <use xlinkHref="/res/sys/icons/material-icons.svg#arrow_back" />
            </svg> : null;
        let panelClass = "";
        let renderIcon = true;
        let buttonAction: React.EventHandler<React.SyntheticEvent<HTMLInputElement | HTMLDivElement>> =
            defaultButtonAction;
        let buttonMessage: string = "";
        const multipleOptions: boolean = !!(authMethods && authMethods.length > 1);

        switch (step) {
            case AuthStep.BADGE:
                panelClass = multipleOptions ? "badge-panel multiple-options" : "badge-panel";
                backArrow = null;
                buttonAction = this.submitBadge;
                buttonMessage = "CONTINUE";
                break;
            case AuthStep.USERNAME:
                panelClass = multipleOptions ? "multiple-options" : "";
                backArrow = null;
                buttonAction = this.submitUsername;
                buttonMessage = "CONTINUE";
                break;
            case AuthStep.PASSWORD:
                panelClass = previousStep === AuthStep.USERNAME ? "password-panel" : "secret-panel";
                panelClass = `${rememberMe ? `remember-me-` : ``}${panelClass}`;
                if (error === ErrorStates.INVALID_MULTIPLE) {
                    panelClass += " multi-line-error";
                }
                buttonAction = previousStep === AuthStep.USERNAME ? this.authenticate : this.submitBadgeAndSecret;
                buttonMessage = "CONTINUE";
                break;
            case AuthStep.VERIFY:
                buttonAction = this.verify;
                buttonMessage = "VERIFY";
                break;
            case AuthStep.PASSWORD_RESET:
                panelClass = "password-reset-panel";
                buttonAction = this.submitPasswordReset;
                backArrow = null;
                buttonMessage = "UPDATE PASSWORD";
                renderIcon = false;
                break;
            default:
                return <div>Loading...</div>;
        }

        return (
            <div className="login-section">
                <div className="login-panel-wrapper">
                    { renderIcon ? this.renderLogo() : null }
                    <div className={`login-panel ${panelClass}`} id="login-panel-content">
                        {backArrow}
                        <div className="panel-body">
                            {this.getPanelHeader(step)}
                            {this.getPanelContent(step)}
                            {this.getPanelFooter(step, buttonAction, buttonMessage)}
                        </div>
                        {this.renderMakerPanel()}
                    </div>
                    { this.renderMessagePanel() }
                    { this.renderPasswordUpdateMessagePanel() }
                    { this.renderExitPanel() }
                </div>
            </div>
        );
    }

    renderExitPanel(): React.ReactElement<HTMLElement> | undefined {
        const { smallLayout } = this.state;
        const redirectBack = () => {
            this.redirectToAuthorizationEndpoint(true);
        };

        const perspectiveClass = App.PERSPECTIVE === this.oidcAuthCodeRequest.app ? "perspective" : "";

        if (smallLayout) {
            return (
                <div className="exit-login-wrapper-small">
                    <button className={`exit-login-button-small ${perspectiveClass}`} onClick={ redirectBack }>EXIT LOGIN</button>
                </div>
            );
        }

        return (
            <div className="exit-login-wrapper">
                <button className={`exit-login-button ${perspectiveClass}`} onClick={ redirectBack }>EXIT LOGIN</button>
            </div>
        );
    }

    renderMakerPanel(): React.ReactElement<HTMLDivElement> | undefined {
        if (PlatformEdition.MAKER === this.props.platformEdition.valueOf()){
            return (
                <div id="maker-message">
                    <p>Licensed for non-commercial use only</p>
                    <a
                        target="_new"
                        href="https://links.inductiveautomation.com/maker-docs-home"
                    >
                        Learn More
                        <svg className="material-icons md-24">
                            <use xlinkHref="/res/sys/icons/material-icons.svg#open_in_new" />
                        </svg>
                    </a>
                </div>
            );
        }
    }

    @bind
    renderMessagePanel(): React.ReactElement<HTMLDivElement> | undefined {
        if (this.state.badgeError) {
            return (
                <div className='authMessagePanel__container'>
                    <div className='authMessagePanel__panel'>
                        <div className='authMessagePanel__icon'>
                            {UserIcon}
                        </div>
                        <div className='authMessagePanel__message'>
                            <div className='authMessagePanel__message__title'>
                                Badge Scan Failed
                            </div>
                            <div className='authMessagePanel__message__body'>
                                Please try again. Possible causes for this error include: Unable to find badge in the system, account lockout, or server error.
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    @bind
    renderPasswordUpdateMessagePanel(): React.ReactElement<HTMLDivElement> | undefined {
        if (this.state.passwordUpdateError) {
            return (
                <div className='authMessagePanel__container'>
                    <div className='authMessagePanel__panel'>
                        <div className='authMessagePanel__icon'>
                            {UserIcon}
                        </div>
                        <div className='authMessagePanel__message'>
                            <div className='authMessagePanel__message__title'>
                                Something Went Wrong
                            </div>
                            <div className='authMessagePanel__message__body'>
                                {"We've encountered an error updating your password. Please try again or contact an administrator for help."}
                            </div>
                        </div>
                    </div>
                    <div className="authMessagePanel__action">
                        <div className="authMessagePanel__action__item" onClick={this.retryLogin}>
                            RETRY LOGIN
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        const { smallLayout } = this.state;
        const currentStep: AuthStep | undefined = this.getCurrentStep();
        const loginPanel = this.getLoginStep(currentStep);
        const edition: PlatformEdition = this.props.platformEdition as PlatformEdition;
        const perspectiveClass: string = App.PERSPECTIVE === this.oidcAuthCodeRequest.app ? "perspective" : "";
        const coBranding = this.props.coBrandingEnabled && App.PERSPECTIVE === this.oidcAuthCodeRequest.app;
        const sidePanelText: string = currentStep === AuthStep.PASSWORD_RESET ? "Update Password" : "Log In";
        if (smallLayout) {
            return (
                <div className={`client-login app-state-page --small --${edition} ${perspectiveClass}`}>
                    <div className={`login-header ${perspectiveClass}`}>
                        <span>{sidePanelText}</span>
                    </div>
                    {loginPanel}
                    <TerminalStateFooter edition={edition} coBrandingEnabled={coBranding}/>
                    <div className={`gradient-background ${perspectiveClass}`}/>
                </div>
            );
        }

        return (
            <div className={`client-login app-state-page --${edition}`}>
                <div className="brand-side-panel">
                    <div className={`login-header ${perspectiveClass}`}>
                        <span>{sidePanelText}</span>
                    </div>
                    <TerminalStateFooter edition={edition} coBrandingEnabled={coBranding}/>
                    <div className={`gradient-background ${perspectiveClass}`}/>
                </div>
                <div className="brand-side-panel-spacer-underlay" />
                { loginPanel }
            </div>
        );
    }
    /* eslintenable: jsx-no-lambda */

}

const InfoIcon = (
    <svg viewBox="0 0 16 16">
        <path d="M0,0h16v16h-16Z" fillRule="evenodd" fill="none" stroke="none"/>
        <path stroke="none" d="M7.33333,11.3333h1.33333v-4h-1.33333v4Zm0.666667,-10c-3.68,-2.22045e-16 -6.66667,2.98667 -6.66667,6.66667c-2.22045e-16,3.68 2.98667,6.66667 6.66667,6.66667c3.68,0 6.66667,-2.98667 6.66667,-6.66667c0,-3.68 -2.98667,-6.66667 -6.66667,-6.66667Zm0,12c-2.94,0 -5.33333,-2.39333 -5.33333,-5.33333c4.44089e-16,-2.94 2.39333,-5.33333 5.33333,-5.33333c2.94,4.44089e-16 5.33333,2.39333 5.33333,5.33333c0,2.94 -2.39333,5.33333 -5.33333,5.33333Zm-0.666667,-7.33333h1.33333v-1.33333h-1.33333v1.33333Z"/>
    </svg>
);

const UserIcon = (
    <svg viewBox="0 0 24 24" width='60' height='60'>
        <path fill="#EF4D4D" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export function defaultButtonAction(): void {
    // noop
}

declare interface ButtonProps {
    action: React.EventHandler<React.SyntheticEvent<HTMLInputElement | HTMLDivElement>>;
    message: string;
    isLoading: boolean;
    passwordResetValid: boolean;
    keyDown: React.EventHandler<React.KeyboardEvent<HTMLInputElement | HTMLDivElement>>;
    isAboveLink: boolean;
    className?: string;
}

class SubmitButton extends React.Component<ButtonProps, any> {

    button: HTMLElement;

    constructor(props: ButtonProps) {
        super(props);
    }

    componentDidUpdate() {
        if (this.props.isLoading && this.button) {
            this.button.blur();
        }
    }

    @bind
    setSubmitButton(button: HTMLButtonElement | HTMLDivElement | null): void {
        if (button) {
            this.button = button;
        }
    }

    render() {
        const { action, message, keyDown, isLoading, passwordResetValid, isAboveLink } = this.props;
        let buttonClass: string, buttonContent: JSX.Element, buttonAction: React.EventHandler<React.SyntheticEvent<any>>;
        const isPasswordInvalid: boolean = message === "UPDATE PASSWORD" && !passwordResetValid;
        if (isLoading) {
            buttonClass = `submit-button button-disabled ${ isAboveLink ? `above-link` : `` }`;
            buttonAction = () => { /** empty */ };
            buttonContent = (
                <div className="loading-wrapper">
                    <div className="ripple-loader">
                        <div/>
                        <div/>
                    </div>
                </div>
            );
        } else {
            buttonClass = `submit-button ${isPasswordInvalid ? "button-disabled" : ""} ${ isAboveLink ? `above-link` : `` }`;
            buttonAction = isPasswordInvalid ? () => { /** empty */ } : action;
            buttonContent = <span className="button-message">{message}</span>;
        }

        return (
            <div
                className={ `${buttonClass} ${this.props.className ?? ""}` }
                ref={ this.setSubmitButton }
                tabIndex={ 0 }
                onClick={ buttonAction }
                onKeyDown={ keyDown }
            >
                { buttonContent }
            </div>
        );
    }
}

// Copied from the perspective client package terminal state
export function TerminalStateFooter(props: { edition: PlatformEdition, coBrandingEnabled: boolean }) {
    const { edition = PlatformEdition.STANDARD, coBrandingEnabled } = props;

    const footerLogo = coBrandingEnabled
        ? <div/>
        : <img src={`/res/sys/img/footer/${edition}-edition-footer-logo.png`} alt={`${edition} Edition`}/>;

    return (
        <div className="logo-wrapper">
            <div className="logo-container">
                { footerLogo }
            </div>
        </div>
    );
}
